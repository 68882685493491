.loader {
  position: relative;
  color: transparent !important;
  pointer-events: none;

  &::after {
    position: absolute;
    top: calc(50% - (1em / 2));
    left: calc(50% - (1em / 2));
    width: 1em;
    height: 1em;
    content: "";
    border: 2px solid #dbdbdb;
    border-top-color: transparent;
    border-right-color: transparent;
    border-radius: 290486px;
    animation: spin-around 500ms infinite linear;
  }
}

@keyframes spin-around {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.u-section-title {
  position: relative;
  margin-bottom: 2rem;
  font-size: 1.25rem;
  line-height: 1.4;
  letter-spacing: 0.031rem;

  @media (min-width: breakpoint-min(sm)) {
    font-size: 1.5rem;
  }

  @media (min-width: breakpoint-min(md)) {
    margin-bottom: 4rem;
  }

  @media (min-width: breakpoint-min(lg)) {
    margin-bottom: 5rem;
  }

  &::after {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    content: "";
  }

  p {
    position: relative;
    z-index: 1;
    display: inline-block;
    padding: 0 1rem;

    @media (min-width: breakpoint-min(sm)) {
      padding: 0 2.5rem;
    }
  }

  &.theme-normal {
    p {
      background-color: #fafafa;
    }

    &::after {
      background: theme-color();
    }
  }

  &.theme-white {
    p {
      background-color: #fff;
    }

    &::after {
      background: theme-color();
    }
  }

  &.text-left p {
    padding-left: 0;
  }

  &.pure::after {
    content: none;
  }
}

.u-section-padding {
  padding: 3rem 0;

  @media (min-width: breakpoint-min(md)) {
    padding: 6.25rem 0;
  }
}

.u-section-padding-md {
  padding: 2rem 0;

  @media (min-width: breakpoint-min(md)) {
    padding: 4.75rem 0;
  }
}

.bg-white + .bg-white,
.bg-gray + .bg-gray {
  padding-top: 0;
}

.bg-gray-gradient {
  background-image: linear-gradient(180deg, #fff 0%, #fafafa 100%);
}

.bg-gray + .bg-white,
.bg-gray + .bg-gray-gradient {
  border-top: 1px solid #f1f1f1;
}

.wysiwyg {
  table {
    /* stylelint-disable scss/at-extend-no-missing-placeholder */
    @extend .table, .table-bordered;
  }

  & > *:not(:last-child) {
    margin-bottom: 1.56rem;
  }

  ul,
  ol {
    padding: 0;

    li {
      &:not(:last-child) {
        margin-bottom: 0.43rem;
      }
    }
  }

  ol {
    list-style: none;
    counter-reset: ol-counter;

    li {
      position: relative;
      padding-left: 1.875rem;
      line-height: 1.4;
    }

    li::before {
      position: absolute;
      top: 0.0625rem;
      left: 0.125rem;
      font-weight: 500;
      color: theme-color();
      content: counter(ol-counter);
      counter-increment: ol-counter;
    }
  }

  ul {
    padding-left: 1rem;
  }
}

.font-weight-semibold {
  font-weight: 600;
}

.text-none {
  font-size: 0;
}

.text-base {
  font-size: 1rem;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.separator {
  width: calc(100% + 15px);
  margin-left: -15px;
  border-bottom: 1px solid rgba(#d1d1d1, 0.3);
}
