@import "/vercel/path0/frontend/scss/common/variables";@import "/vercel/path0/frontend/node_modules/bootstrap/scss/functions";@import "/vercel/path0/frontend/node_modules/bootstrap/scss/variables";@import "/vercel/path0/frontend/node_modules/bootstrap/scss/mixins";
@import "common/variables";
@import "common/bootstrap";
@import "common/utils";
@import "~hamburgers/_sass/hamburgers/hamburgers";
@import "~slick-carousel/slick/slick.css";

html,
body {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-overflow-scrolling: touch;

  & > .wrap {
    flex: 1 0 auto;
  }
}

p,
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}

b,
strong {
  font-weight: 700;
}

a {
  transition: color 0.3s ease;
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.019rem;

  svg {
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 0.65rem;
  }

  &.btn-primary {
    &:hover {
      background: #5e8397;
      border-color: #5e8397;
    }

    &:active {
      background: #335b70;
      border-color: #335b70;
    }
  }

  &.btn-mobile-block {
    @media (max-width: breakpoint-max(sm)) {
      width: 100%;
    }
  }
}

.form-group {
  label:not(.custom-control-label) {
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.5;
    color: #5c6670;
    letter-spacing: 0.016rem;
  }
}

.custom-control-label {
  font-size: 0.875rem;
}

.invalid-feedback {
  letter-spacing: 0.2px;
}

.slick-slider.equal .slick-track {
  display: flex;

  .slick-slide {
    display: flex;
    height: auto;
  }
}

button {
  &.loading {
    position: relative;
    color: transparent !important;
    pointer-events: none;

    &::after {
      position: absolute;
      top: calc(50% - (1em / 2));
      left: calc(50% - (1em / 2));
      width: 1em;
      height: 1em;
      content: "";
      border: 2px solid #dbdbdb;
      border-top-color: transparent;
      border-right-color: transparent;
      border-radius: 290486px;
      animation: spinAround 500ms infinite linear;
    }
  }
}

.table {
  font-size: 0.875rem;

  thead th {
    font-size: 0.625rem;
    font-weight: normal;
    color: #5c6670;
    text-transform: uppercase;
    letter-spacing: 0.2px;
    border-top: 0;
    border-bottom: 0;
  }

  tbody {
    tr:first-child td {
      border-top: 0;
    }
  }
}

.form-control {
  @media (min-width: breakpoint-min(md)) {
    font-size: 0.875rem;
  }
}

.form-control:disabled,
.form-control[readonly] {
  color: #9c9997;
}

.modal {
  .modal-header {
    display: flex;
    align-items: center;

    .close {
      padding: 0.5rem;
      margin: 0;
    }
  }

  .modal-title {
    @include font-size(1.5rem);
  }

  .modal-content {
    box-shadow: 0 16px 40px rgb(0 0 0 / 6.26%);
  }

  .modal-body {
    padding: 0 $modal-inner-padding;
  }
}

.close {
  span:first-child {
    display: block;
    width: 13px;
    height: 13px;
    font-size: 0;
    background: url(/images/close.svg) no-repeat;
  }
}

.alert {
  @media (max-width: breakpoint-max(sm)) {
    font-size: 0.875rem;
  }

  &.alert-danger {
    display: flex;
    align-items: center;
    min-height: 48px;
    padding-left: 45px;
    font-size: 12px;
    color: #1b1b1b;
    background:
      linear-gradient(0deg, rgba(230, 0, 3, 0.05), rgba(230, 0, 3, 0.05)),
      url("/images/lucide_alert-octagon.svg") 12px 12px no-repeat,
      #fff;
    border: 1px solid #e60003;
  }
}
