@mixin touch-edges {
  @media (max-width: breakpoint-max(xs)) {
    width: calc(100% + 30px);
    margin-left: -15px;
  }
}

$color-primary: #29667f;
$color-secondary: #5c6670;
$theme-colors: (
  primary: $color-primary,
  secondary: $color-secondary,
  "gray": #fafafa,
  danger: #e60003,
);
$body-color: #1b1b1b;
$input-border-color: #ededed;
$input-placeholder-color: #5c6670;
$input-focus-border-color: $color-primary;
$input-focus-box-shadow: none;
$input-height: 3rem;
$input-border-radius: 0.25rem;
$input-padding-x: 0.75rem;
$input-font-size: 1rem;
$input-disabled-bg: #fafafa;
$form-feedback-font-size: 0.625rem;
$form-group-margin-bottom: 1.5rem;
$font-family-sans-serif: "Muli", sans-serif;
$enable-responsive-font-sizes: true;
$enable-validation-icons: false;
$btn-border-radius: 0;
$btn-font-weight: 700;
$btn-font-size: 0.875rem;
$btn-padding-x: 1.2rem;
$btn-padding-y: 0.7rem;
$navbar-light-color: #5a6067;
$navbar-light-hover-color: $color-primary;
$navbar-light-active-color: $color-primary;
$navbar-nav-link-padding-x: 0;
$navbar-padding-y: 1rem;
$hamburger-padding-x: 0;
$hamburger-padding-y: 0;
$hamburger-layer-width: 24px;
$hamburger-layer-height: 2px;
$hamburger-layer-spacing: 8px;
$hamburger-layer-color: currentColor;
$hamburger-layer-border-radius: 0;
$hamburger-hover-opacity: 1;
$hamburger-types: (squeeze);
$breadcrumb-bg: transparent;
$breadcrumb-margin-bottom: 0;
$breadcrumb-padding-x: 0;
$breadcrumb-active-color: #1b1b1b;
$breadcrumb-divider: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='10' viewBox='0 0 6 10'%3E%3Cpath fill='%2329667f' d='M.67.67a.467.467 0 01.602-.05l.058.05 4 4c.164.164.18.42.05.602l-.05.058-4 4a.467.467 0 01-.71-.602l.05-.058L4.34 5 .67 1.33A.467.467 0 01.62.728z'/%3E%3C/svg%3E");
$custom-control-indicator-size: 1.5rem;
$custom-control-indicator-border-color: #9c9997;
$custom-control-indicator-checked-border-color: $color-primary;
$custom-control-indicator-checked-bg: #fff;
$custom-control-indicator-checked-color: $color-primary;
$input-group-addon-bg: #fafafa;
$input-group-addon-color: #5c6670;
$table-accent-bg: #fafafa;
$table-border-color: #e4e4e4;
$table-cell-padding: 0.75rem;
$tooltip-font-size: 0.75rem;
$tooltip-padding-x: 0.75rem;
$tooltip-padding-y: 0.5rem;
$modal-header-border-width: 0;
$modal-content-border-radius: 0;
$modal-content-border-width: 0;
$modal-md: 656px;
$modal-backdrop-opacity: 0.7;
$modal-inner-padding: 1rem;
$modal-header-padding-y: 1.5rem;
$modal-header-padding-x: 1rem;
